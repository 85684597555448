<template>
  <v-layout row wrap focus>
    <v-col cols="12">
      <div class="select-backdrop">
        <v-text-field
          class="light-secondary-select"
          solo
          background-color="#3E6B86"
          append-icon="mdi-chevron-down"
          @click="dateDialog = true"
          hide-details
          readonly
        >
          <template slot="prepend-inner">
            Date
          </template>
          <template slot="label">
            {{ formattedDate }}
          </template>
        </v-text-field>

        <v-dialog
          ref="dialog"
          v-model="dateDialog"
          :return-value.sync="filters.date"
          title-date-format="ddd, D MMM YYYY"
          header-color="#FCF1EA"
          width="290px"
        >
          <v-date-picker
            event-color="secondary"
            color="#FBE8DC"
            v-model="date"
            scrollable
            selected-items-text="secondary"
            class="custom-date"
          >
            <v-spacer></v-spacer>
            <v-btn text color="brown" @click="dateDialog = false">Cancel</v-btn>
            <v-btn text color="brown" @click="$refs.dialog.save(date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="select-backdrop">
        <span class="backdrop" v-if="backdrop1" @click="closeBackdrop"></span>
        <v-select
          v-model="filters.ngo"
          :items="$store.getters.myNgos"
          item-text="nameEn"
          item-value="id"
          class="light-secondary-select"
          solo
          background-color="#3E6B86"
          :append-icon="'mdi-chevron-' + (backdrop1 ? 'up' : 'down')"
          hide-details
          :auto-select-first="true"
          @change="backdrop1 = false"
          @mousedown="backdrop1 = !backdrop1"
          :menu-props="{ bottom: true, offsetY: true, value: backdrop1 }"
        >
          <template slot="label">
            Select ngo
          </template>
          <template slot="prepend-inner">
            NGO
          </template>
        </v-select>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="select-backdrop">
        <span class="backdrop" v-if="backdrop2" @click="closeBackdrop"></span>
        <v-select
          v-model="filters.status"
          :items="$store.state.runStatuses"
          item-text="title"
          class="light-secondary-select"
          solo
          :auto-select-first="true"
          background-color="#3E6B86"
          :append-icon="'mdi-chevron-' + (backdrop2 ? 'up' : 'down')"
          hide-details
          @change="backdrop2 = false"
          @mousedown="backdrop2 = !backdrop2"
          :menu-props="{ bottom: true, offsetY: true, value: backdrop2 }"
        >
          <template slot="label">
            All
          </template>
          <template slot="prepend-inner">
            Status
          </template>
        </v-select>
      </div>
    </v-col>
    <v-col cols="12" v-for="run in orderedRuns" :key="run.id">
      <div class="card-wrapper">
        <run-card :run="run" v-on:estimate="estimateRun"/>
      </div>
    </v-col>
    <v-dialog
      ref="adminDialog"
      v-model="adminDialog"
    >
      <div class="admin-form-block welcome">
        <v-card
          elevation="0"
          class="text-center mb-10"
        >
          <v-card-title class="justify-center pb-2">{{ run2estimate.donor.nameEn }}</v-card-title>
          <v-card-subtitle class="mt-0">{{ formattedDate }}</v-card-subtitle>
        </v-card>
        <div class="auth-title">
          <span>Estimate Amount</span>
        </div>
        <v-row class="estimate-wrapper">
          <v-col
            v-for="amount of amounts"
            :key="amount.id"
            cols="12"
            sm="6"
          >
            <v-btn
              color="#FCF1EA"
              height="72"
              class="px-5"
              :ripple="false"
              :class="{'selected': selectedAmount.id === amount.id}"
              @click="select(amount)"
              @dblclick="clear()"
            >
              <img :src="amount.icon">
              <span :class="{'simple-text':!amount.icon}">{{ amount.text }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          large
          width="276"
          max-width="100%"
          rounded
          class="mb-6 mt-7 ml-auto mr-auto"
          @click="confirm"
        >
          CONFIRM
        </v-btn>
      </div>
    </v-dialog>
  </v-layout>
</template>

<script>
import * as runService from '@/services/run';
import RunCard from '../../components/run-card';
import _ from 'lodash';

export default {
  components: { RunCard },
  timer: '',
  metaInfo: {
    title: 'Runs'
  },
  data () {
    return {
      dialog: false,
      dateDialog: false,
      selected: [],
      date: null,
      isPublicHoliday: false,
      rundate: null,
      testrun: null,
      status: null,
      runs: [],
      adminDialog: false,
      run2estimate: {
        donor: {
          nameEn: 'placeholder'
        }
      },
      filters: {
        date: null
      },
      backdrop1: false,
      backdrop2: false,
      selectedAmount: {},
      amounts: [
        {
          id: 1,
          icon: '/images/donor-bread-1.svg',
          text: '1 - 20',
          value: '1-20'
        },
        {
          id: 2,
          icon: '/images/donor-bread-21.svg',
          text: '21 - 30',
          value: '21-30'
        },
        {
          id: 3,
          icon: '/images/donor-bread-31.svg',
          text: '31+',
          value: '31+'
        },
        {
          id: 4,
          icon: false,
          text: '售罄 SOLD OUT',
          value: '0'
        }
      ]
    };
  },
  computed: {
    formattedDate () {
      return this.$moment(this.filters.date).format('ddd, D MMM YYYY');
    },
    orderedRuns () {
      if (this.isPublicHoliday) {
        const weekFilter = 'donor.pickupTimes.PH';
        return _.sortBy(this.runs, weekFilter);
      } else {
        const dayOfTheWeek = this.$moment(this.filters.date).format('ddd');
        const weekFilter = 'donor.pickupTimes.'.concat(dayOfTheWeek);
        return _.sortBy(this.runs, weekFilter);
      }
    },
    dates () {
      const dateArr = [];
      const today = new Date();
      for (let i = 0; i < 4; i++) {
        let nextDay = today.setDate(new Date().getDate() + i);
        dateArr.push({
          text: i === 0 ? 'Today' : this.$moment(nextDay).format('ddd, D MMM'),
          value: this.$moment(nextDay).format('YYYY-MM-D')
        });
      }
      return dateArr;
    }
  },
  created () {
    this.date = this.filters.date = this.$moment().format('YYYY-MM-DD');
    this.timer = setInterval(this.getRuns, 60000);
    this.rundate = this.$moment(this.date).format('YYYYMMDD');
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  methods: {
    getRuns () {
      runService.get(this.filters)
        .then(response => {
          this.runs = response;
          this.getPublicHoliday();
        });
    },
    estimateRun (id) {
      this.adminDialog = true;
      this.run2estimate = id;
    },
    closeBackdrop () {
      this.backdrop1 = false;
      this.backdrop2 = false;
    },
    select (amount) {
      this.selectedAmount = amount;
    },
    clear () {
      this.selectedAmount = {};
    },
    getPublicHoliday () {
      this.isPublicHoliday = this.runs[0].publicholiday;
    },
    confirm () {
      let id = String(this.run2estimate.id);
      if (_.isEmpty(this.selectedAmount)) {
        let data = {
          expectedAmount: null
        };
        runService.update(id, data)
          .then(() => {
            this.adminDialog = false;
            this.getRuns();
          });
      } else {
        let data = {
          expectedAmount: this.selectedAmount.value
        };
        runService.update(id, data)
          .then(() => {
            this.adminDialog = false;
            this.selectedAmount = {};
            this.getRuns();
          });
      }
    }
  },
  watch: {
    filters: {
      handler () {
        this.getRuns();
        this.rundate = this.$moment(this.filters.date).format('YYYYMMDD');
      },
      deep: true
    }
  }
};
</script>
