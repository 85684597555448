<template>
  <v-layout row wrap focus>
    <v-col cols="12">
      <div class="select-backdrop">
        <v-text-field
          class="light-secondary-select"
          solo
          background-color="#3E6B86"
          append-icon="mdi-chevron-down"
          @click="dateDialog = true"
          hide-details
          readonly
        >
          <template slot="prepend-inner">
            Date
          </template>
          <template slot="label">
            {{ formattedDate }}
          </template>
        </v-text-field>

        <v-dialog
          ref="dialog"
          v-model="dateDialog"
          :return-value.sync="filters.date"
          title-date-format="ddd, D MMM YYYY"
          header-color="#FCF1EA"
          width="290px"
        >
          <v-date-picker
            event-color="secondary"
            color="#FBE8DC"
            v-model="date"
            scrollable
            selected-items-text="secondary"
            class="custom-date"
          >
            <v-spacer></v-spacer>
            <v-btn text color="brown" @click="dateDialog = false">Cancel</v-btn>
            <v-btn text color="brown" @click="$refs.dialog.save(date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-col>
    <v-col cols="12">
      <drop-select
        :items="availableDropoff"
        :selected-item="selectedDropoffId"
        :item-text-en="nameEn"
        :item-text-zh="nameZh"
        @input="selectDrop($event)"
        prefix="Drop off"
        color="#F7D1B8"
        background-color="#3E6B86"
        clearable
        secondary
      />
    </v-col>
    <v-col cols="12">
      <div class="volunteer-wrapper pt-4">
        <template v-if="volunteers.length">
          <v-card
            elevation="0"
            color="rgba(207,218,225,0.2)"
            class="volunteer-block mb-1"
            tile
            v-for="volunteer in filteredVolunteersByDropoff"
            :key="volunteer.id"
          >
            <v-card-title class="px-5 pt-2 pb-0 justify-space-between">
              <a :href="'tel:'+volunteer.telephone">
                <div class="name"><strong>{{ volunteer.name }}</strong></div>
              </a>
              <div class="runs">
                <span> est.: </span>
                <strong>{{ volunteer.estimate }}</strong>&nbsp;
                <span> pcs</span>
              </div>
            </v-card-title>
            <v-card-subtitle class="px-5 pb-3 mt-0 d-flex justify-space-between">
              <div class="name"><strong></strong></div>
              <div class="runs">
                <span> Dropoff: </span>
                <strong>{{volunteer.dropoff.nameEn}}</strong>&nbsp;
              </div>
            </v-card-subtitle>
          </v-card>
        </template>
        <div v-else class="volunteer-empty px-5 text-center">No records</div>
      </div>
    </v-col>
  </v-layout>
</template>

<script>
import * as volunteerService from '@/services/volunteer';
import DropSelect from '@/components/drop-select.vue';
export default {
  name: 'volunteers',
  timer: '',
  components: {
    DropSelect
  },
  metaInfo: {
    title: 'Volunteers'
  },
  data () {
    return {
      dateDialog: false,
      selectedDropoffId: null,
      volunteers: [],
      date: null,
      nameEn: 'nameEn',
      nameZh: 'nameZh',
      filters: {
        date: null
      }
    };
  },
  computed: {
    formattedDate () {
      return this.$moment(this.filters.date).format('ddd, D MMM YYYY');
    },
    filteredVolunteersByDropoff () {
      return this.selectedDropoffId ? this.volunteers.filter(volunteer => volunteer.dropoff?.id === this.selectedDropoffId) : this.volunteers;
    },
    availableDropoff () {
      return [...new Set(this.volunteers.map(volunteer => volunteer.dropoff))];
    }
  },
  created () {
    this.date = this.filters.date = this.$moment().format('YYYY-MM-DD');
    this.timer = setInterval(this.getVolunteersByDate, 60000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  methods: {
    getVolunteersByDate () {
      volunteerService.getVolunteersByDate(this.date)
        .then(response => {
          this.volunteers = response;
        });
    },
    selectDrop (id) {
      this.selectedDropoffId = id;
    }
  },
  watch: {
    filters: {
      handler () {
        this.getVolunteersByDate();
        this.rundate = this.$moment(this.filters.date).format('YYYYMMDD');
      },
      deep: true
    }
  }
};
</script>
