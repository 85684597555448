<template>
  <v-card flat class="admin">
    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'index',
  metaInfo: {
    title: 'Admin'
  },
  data () {
    return {
      current: 0
    };
  }
};
</script>
