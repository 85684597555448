<template>
  <div class="select-backdrop">
    <span :v-if="backdrop" :class="backDropClass" @click="clearBackdrop"/>
    <v-select
      :clearable="clearable"
      v-model="internalValue"
      :items="items"
      :item-text="itemText"
      :item-value="itemId"
      :class="selectClass"
      flat
      solo
      :auto-select-first="true"
      :background-color="backgroundColor"
      :append-icon="icon"
      @click="toggleBackdrop"
      hide-details
      :color="color"
      :menu-props="menuProps"
    >
      <template slot="prepend-inner">
        {{ prefix }}
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data: () => ({
    backdrop: false,
    internalValue: null
  }),
  props: {
    itemTextEn: {
      type: String,
      default: 'name_en'
    },
    itemTextZh: {
      type: String,
      default: 'name_zh'
    },
    itemId: {
      type: String,
      default: 'id'
    },
    selectedItem: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#0A3F60'
    },
    backgroundColor: {
      type: String,
      default: '#FCF1EA'
    },
    clearable: {
      type: Boolean
    },
    secondary: {
      type: Boolean
    }
  },
  methods: {
    updateInternalValue (newVal) {
      this.internalValue = newVal;
    },
    updateType (newVal) {
      this.$emit('input', newVal);
      this.clearBackdrop();
    },
    clearBackdrop () {
      this.backdrop = false;
    },
    toggleBackdrop () {
      this.backdrop = true;
    }
  },
  computed: {
    icon () {
      return `mdi-chevron-${this.backdrop ? 'up' : 'down'}`;
    },
    itemText () {
      return this.$i18n.locale === 'en' ? this.itemTextEn : this.itemTextZh;
    },
    menuProps () {
      return {
        bottom: true,
        offsetY: true,
        value: this.backdrop
      };
    },
    backDropClass () {
      return this.backdrop ? 'backdrop' : '';
    },
    selectClass () {
      return `light-${this.typeClass}-select label-left ${this.overlayClass}`;
    },
    overlayClass () {
      return this.backdrop ? 'select-overlay' : 'no-select-overlay';
    },
    typeClass () {
      return this.secondary ? 'secondary' : 'primary';
    }
  },
  mounted () {
    this.internalValue = this.selectedItem;
  },
  watch: {
    'internalValue': 'updateType',
    'selectedItem': 'updateInternalValue'
  }
};
</script>
<style>
.select-overlay {
  overlay: true
}
.no-select-overlay {
  overaly: false
}
</style>
